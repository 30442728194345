@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
}
body {
  font-family: "Inter", sans-serif;
}

.loginBg {
  background-image: url("../assets/img/plaque_imma_login.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
}

.homeBanner {
  background-image: url("../assets/img/bggg.png");
  background-repeat: repeat;
  background-size: contain;
}


.bgHome {
  background-image: url("../assets/img/bghomee.png");
  background-repeat: no-repeat;
  background-size:cover;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.0);
}
